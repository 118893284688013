<template>
  <div class="page">
    <!--搜索-->
    <div class="nav flexcenter">
      <van-search v-model="searchValue" placeholder="搜索商品名称" />
    </div>
  
    <div class="content-box">
      <!--切换栏-->
      <div class="tab-box">
        <div :class="['item', {active: tab === idx}]"
             v-for="(item, idx) in sliderMenusData"
             :key="item.v_name"
             @click="toggleTab(idx)"
        >{{item.v_name}}</div>
      </div>
      <!--商品列表-->
      <div class="cont">
        <!--商品详情-->
        <product2
            v-for="(item, idx) in goodsList"
            :key="item.goods_id"
            :goods="item"
            :number.sync="item.goods_number"
            :addCart="addCart"
            :reduceToCart="reduceToCart"
        ></product2>
      </div>
    </div>
  
    <div class="bottom flexcenter">
      <div class="yixuan" bindtap="showChosenBox">{{familyInfo}},已选 {{cartGoodsTotal}} 件</div>
      <div class="cashier flexcenter">
        <div class="heji">合计: </div>
        <div class="price">￥{{cartGoodsAmount}}</div>
        <div v-if="familyInfo==''" class="buy" @click="goCheckout">去结算</div>
        <div v-else class="buy" @click="selectOk">完成</div>
      </div>
    </div>
  
    <!--已选弹窗-->
    <van-popup
        v-model="isShowChosen"
        position="bottom"
        closeable
    >
      <div class="chosen-box">
        <div class="list" scroll-y="true">
          <product1
              v-for="(item, idx) in cartGoods"
              :key="item.goods_id"
              :goods="item"
              :refeshPage="refeshPage"
          ></product1>
        </div>
      </div>
    </van-popup>
  </div>
</template>

<script>
  import mixin from 'utils/mixin'
  import product1 from 'components/product1'
  import product2 from 'components/product2'
  
  export default {
    name: 'daoyou-normal',
    mixins: [mixin],
    data() {
      return {
        searchValue: '',
        tab: 0,
        page:0,
        totalPage:0,
        totalNumber:0,
        filters:{},  //商品筛选条件
        goodsList:[], //商品数据
        isShowChosen: false,
        showSkuPop:false,
        skuPopGoodsInfo:[],
        selectedSkuId:'',
        selectedSkuName:'请选择',
        selectedSkuNumber:'1',
        cartGoods:[], //购物车商品信息
        cartGoodsTotal:0,//购物车商品数量
        cartGoodsAmount:0,//合计
        sliderMenusData:[], //侧栏
        familyInfo:'' //不是空则是家庭模式
      }
    },
    components: {
      product1,
      product2,
    },
    created() {
      let family = this.$route.query.family;
      if(family){
        this.familyInfo = family
      }

      this.getGoodsList(1, 1,0);
      this.cartGoodsList();
      this.refeshCartTotal();
      this.sliderMenus();
    },
    methods: {
      // 切换按钮
      toggleTab(idx){
        let current = this.sliderMenusData[idx];
        let filters = this.filters;

        if(current.v_category!=''){ filters.category_id = current.v_category; }
        if(current.v_brand!=''){ filters.brand = current.v_brand; }
        if(current.v_group!=''){ filters.groups = current.v_group; }
        if(current.v_keywords!=''){ filters.keywords = current.v_keywords; }
        if(current.v_tags!=''){ filters.tags = current.v_tags; }

        this.tab = idx
        this.filters = filters

        this.getGoodsList(1, 1);
      },
      showChosenBox() {
        this.cartGoodsList();
        this.isShowChosen = true
      },
      hideChoenBox() {
        this.isShowChosen = false
      },
      refeshPage:function(){
        this.refeshCartTotal();
        this.cartGoodsList();
      },
      /**
       * 结算
       */
      goCheckout:function() {
        this.$router.push({
          name: 'spjs'
        })
      },
      /**完成 */
      selectOk:function(){
        this.$router.push({
          name: 'daoyou-family'
        })
      },
      /**
       * 获取商品菜单
       */
      sliderMenus:function(){
        let that = this;
        this.post(
          this.API.sliderMenus,{}
        ).then(response => {
          this.sliderMenusData = response.data.silderMenus
        })
      },
      searchGoods(e){
        let filters = this.filters;
        filters.keywords = e.detail;
        
        this.filters = filters
        this.searchValue = e.detail
        this.isOffenHidden = true
        this.getGoodsList(1,true);
      },
    }
  };
</script>

<style lang="scss" scoped>
  .page {
    min-height: 100%;
    background-color: #F5F5F5;
  }
  .van-search {
    padding-top: 10px!important;
    width: 100%;
  }
  .van-search__content {
    border-radius: 44px!important;
  }

  .content-box {
    display: flex;
  }

  .tab-box {
    width: 160px;
    height: 100vh;
    background-color: #F5F5F5;
  }
  .tab-box .item {
    height: 100px;
    line-height: 100px;
    color: #191919;
    font-size: 28px;
    text-align: center;
  }
  .tab-box .active {
    font-weight: bold;
    background-color: #FFFFFF;
  }

  .cont {
    flex: 1;
    box-sizing: border-box;
    padding-left: 18px;
    padding-right: 20px;
  }


  .chosen-box {
    padding-bottom: 130px;
  }
  .chosen-box .list {
    height: 660px;
  }

  .bottom {
    justify-content: space-between;
    position: fixed;
    z-index: 1000;
    left: 0;
    right: 0;
    bottom: 0;
    box-sizing: border-box;
    padding: 0 30px;
    height: 110px;
    background-color: #FFFFFF;
  }
  .bottom .yixuan {
    width: 210px;
    height: 52px;
    line-height: 52px;
    border-radius: 32px;
    background-color: #FEF5E7;
    color: #191919;
    text-align: center;
    font-size: 24px;
  }
  .bottom .heji {
    font-size: 24px;
    color: #191919;
  }
  .bottom .price {
    margin-right: 40px;
    font-size: 30px;
    color: #F84F41;
  }
  .bottom .buy {
    width: 192px;
    height: 72px;
    line-height: 72px;
    border-radius: 36px;
    background-color: #FCC601;
    text-align: center;
    font-size: 30px;
    color: #FFFFFF;
  }

</style>
